.success-snackbar {
  border-radius: 5px;
  padding: 15px;
  border-left: 5px solid #12af23;
}

.error-snackbar {
  border-radius: 5px;
  padding: 15px;
  border-left: 5px solid #ff4d4d;
}
